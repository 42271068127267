import { Dispatch, SetStateAction } from 'react';

export interface FileUploaderProps {
  headerText: string;
  fileIcon: string;
  fileType: string;
  fileInfo: FileInfo;
  setFile: Dispatch<SetStateAction<FileInfo>>;
}

export interface FieldValueTableProps {
  fileInfo: FileInfo;
}

export interface FieldValueTableRow {
  Field: string;
  Value: string;
}

export interface FieldValueTableRows {
  rows: FieldValueTableRow[];
}

export interface ValidationItemProps {
  text: string;
  validationState: ValidationState;
}

export interface ValidationStateImageProps {
  validationState: ValidationState;
}

export interface FileInfo {
  name: string;
  file?: File;
}

export interface PdfPreviewProps {
  fileInfo: FileInfo;
}

export enum ValidationState {
  Valid,
  Invalid,
  Notchecked,
}

export interface DataJSON extends FileInfo {
  data: {
    documentFields: {};
    documentHash: string;
  };
  salts: {
    documentFields: {};
    documentHash: string;
  };
  hash: string;
}

export interface ProofJSON extends FileInfo {
  dataHash: string,
  smtId: string,
  inclusionProofs: [],
  block: {
    signatures: [],
    previousBlockHash: string,
    merkleTreeRoot: string,
    id: string,
    createdAt: Date,
    updatedAt: Date
  },
  blockHash: string
}

export interface EmptyPdfContainerProps {
  message: string;
}