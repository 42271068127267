// Delay for ms millisiconds.
const delay = (ms: number) => new Promise(res => setTimeout(res, ms));

// If the fetch fails for some reason, like a network error, this function still returns with a not OK response, instead of simply throwing an exception.
const fetchWithTryCatch = async (url: string, requestInit: RequestInit) => {
  try {
    return await fetch(url, requestInit);
  }
  catch {
    return new Response(new Blob(), { status: 400, statusText: "Failed to send request!" });
  }
}

// Try to fetch and retry the given amount of times with the given wait time.
export const sendRequestWithRetry = async (url: string, requestInit: RequestInit, retryCount: number, waitTimeInMS: number) => {
  let response = await fetchWithTryCatch(url, requestInit);

  while(retryCount > 0 && !response.ok) {
    retryCount--;
    await delay(waitTimeInMS);
    response = await fetchWithTryCatch(url, requestInit);
  }

  return response;
}