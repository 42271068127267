import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import { FileUploaderProps } from '../../interfaces';

const FileUploadContainer = styled.section`
  position: relative;
  border: 2px dotted #781e69;
  padding: 20px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
`;

const FormField = styled.input`
  font-size: 18px;
  display: block;
  width: 100%;
  border: none;
  text-transform: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;

  &:focus {
    outline: none;
  }
`;

function FileUploader(props: FileUploaderProps) {
  const handleNewFileAdded = (files: FileList | null) => {
    if (files != null && files[0].type === props.fileType) {
      props.setFile({
        name: files[0].name,
        file: files[0],
      });
    }
  };

  return (
    <FileUploadContainer>
      <Typography variant="h6" color="#781e69">
        {props.headerText}
      </Typography>
      <FormField
        type="file"
        title=""
        value=""
        accept={props.fileType}
        onChange={(e) => handleNewFileAdded(e.target.files)}
      />
      <img src={props.fileIcon} height="50px" alt="file type icon" />
      <Typography sx={{ mt: 2, color: 'darkgray' }} variant="subtitle2">
        {props.fileInfo.name}
      </Typography>
    </FileUploadContainer>
  );
}

export default FileUploader;
