import { MouseEvent, useState } from 'react';
import { EmptyPdfContainerProps, PdfPreviewProps } from '../../interfaces';
import { Document, Page, pdfjs } from 'react-pdf';
import { Button, Container, Typography } from '@mui/material';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function PdfPreview(props: PdfPreviewProps) {
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: any }) => {
    setNumPages(numPages);
  };

  function goToPrevPage(e: MouseEvent) {
    e.preventDefault();
    setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  }

  const goToNextPage = (e: MouseEvent) => {
    e.preventDefault();
    setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);
  };

  const url =
    props.fileInfo.file !== undefined
      ? URL.createObjectURL(props.fileInfo.file)
      : '';

  return (
    <Container>
      <nav>
        <Button
          onClick={(e) => goToPrevPage(e)}
          sx={{
            background: '#781e69',
            color: 'white',
            border: 1,
            borderColor: '#781e69',
            borderRadius: 20,
            pl: 3,
            pr: 3,
            mr: 2,
            '&:hover': {
              backgroundColor: '#fff',
              color: '#781e69',

              boxShadow: 5,
            },
          }}
        >
          <Typography variant="button">Prev</Typography>
        </Button>
        <Button
          onClick={(e) => goToNextPage(e)}
          sx={{
            background: '#781e69',
            color: 'white',
            border: 1,
            borderColor: '#781e69',
            borderRadius: 20,
            pl: 3,
            pr: 3,
            mr: 1,
            '&:hover': {
              backgroundColor: '#fff',
              color: '#781e69',
              boxShadow: 5,
            },
          }}
        >
          <Typography variant="button">Next</Typography>
        </Button>
        <Typography variant="subtitle2" sx={{ ml: 1, mt: 1 }}>
          Page {pageNumber} of {numPages}
        </Typography>
      </nav>
      <Document
        file={url}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={<EmptyPdfContainer message="Loading PDF..." />}
        noData={
          <EmptyPdfContainer message="Drag and drop a certificate file to view..." />
        }
        error={<EmptyPdfContainer message="Failed to load PDF file." />}
      >
        <Container
          id="PdfContainer"
          sx={{
            boxShadow: 2,
            borderRadius: 2,
            height: '80vh',
            p: 2,
            overflow: 'scroll',
          }}
        >
          <Page
            pageNumber={pageNumber}
            renderMode="canvas"
            renderTextLayer={false}
            renderAnnotationLayer={false}
            renderInteractiveForms={false}
          />
        </Container>
      </Document>
    </Container>
  );
}

function EmptyPdfContainer(props: EmptyPdfContainerProps) {
  return (
    <Container
      id="PdfContainer"
      sx={{
        boxShadow: 2,
        p: 2,
        borderRadius: 2,
        height: '80vh',
        overflow: 'scroll',
      }}
    >
      <Typography variant="body1">{props.message}</Typography>
    </Container>
  );
}

export default PdfPreview;
