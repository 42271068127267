import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useState } from 'react';
import {
  FieldValueTableProps,
  FieldValueTableRows,
  FieldValueTableRow,
} from '../../interfaces';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#781e69',
    color: 'white',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function FieldValueTable(props: FieldValueTableProps) {
  const defaultTableData: FieldValueTableRows = {
    rows: [],
  };

  const [tableData, setTableData] = useState(defaultTableData);

  function getRowsFromJson() {
    if (props.fileInfo.file !== undefined) {
      let reader = new FileReader();
      reader.readAsText(props.fileInfo.file);
      reader.onload = () => {
        if (typeof reader.result === 'string') {
          handleFile(reader.result);
        }
      };
    }
  }

  const handleFile = (result: string) => {
    var json = JSON.parse(result);

    const newTableData: FieldValueTableRows = {
      rows: [],
    };

    try {
      if (json.data.documentFields != null) {
        Object.entries(json.data.documentFields).forEach(([key, value]) => {
          let row: FieldValueTableRow = {
            Field: key,
            Value: String(value),
          };
          newTableData.rows.push(row);
        });
      }
    } catch (e) {}

    setTableData(newTableData);
  };

  getRowsFromJson();
  return (
    <TableContainer component={Paper} sx={{ height: 400 }}>
      <Table size="small" stickyHeader>
        <caption>* field names and values obtained from the data json.</caption>
        <TableHead>
          <TableRow>
            <StyledTableCell>Field</StyledTableCell>
            <StyledTableCell align="left">Value</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.rows.map((row) => (
            <StyledTableRow key={row.Field}>
              <TableCell component="th" scope="row">
                {row.Field}
              </TableCell>
              <TableCell align="left">{row.Value}</TableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default FieldValueTable;
