import React, { useEffect } from 'react';
import FileUploader from './modules/components/FileUploader';
import jsonFile from './img/icons8-json-100.png';
import pdfFile from './img/icons8-pdf-100.png';
import FieldValueTable from './modules/components/FieldValueTable';
import logo from './img/cryptar-logo.svg';
import ValidationItem from './modules/components/ValidationItem';
import { Button, Grid, Typography } from '@mui/material';
import { ValidationState, FileInfo, DataJSON, ProofJSON } from './interfaces';
import { useState } from 'react';
import PdfPreview from './modules/components/PdfPreview';
import { sha256 } from 'crypto-hash';
import { sendRequestWithRetry } from './helpers';

const notAPIURL = 'https://notarization-objecthash.azurewebsites.net/api';
const cryptarServerURL = 'https://cryptarfunctions.azurewebsites.net';
const cryptarAPIDemoAppKey = 'rPdU0e456xAEuWcb8UWXh1rtUJEG-f6akzU-BdHIMrlbAzFuzvO1xw==';

const validatePDF = async (
  pdf: FileInfo,
  data: FileInfo,
  setPdfIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof data.file === 'undefined' || typeof pdf.file === 'undefined')
      return;

    let dataContent = await data.file.text();
  
    if (typeof dataContent === 'string') {
      const dataJSON: DataJSON = JSON.parse(dataContent);

      if (typeof pdf.file === 'undefined') return;

      const hash = await sha256(await pdf.file.arrayBuffer());
      setPdfIsValid(
        hash === dataJSON.data.documentHash
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const validateDataJSON = async (
  data: FileInfo,
  setDoubleCheckedDataJSON: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof data.file === 'undefined') return;

    let dataContent = await data.file.text();

    if (typeof dataContent === 'string') {
      const dataJSON: DataJSON = JSON.parse(dataContent);

      const response = await sendRequestWithRetry(notAPIURL + '/rehash-object', {
        method: 'POST',
        body: JSON.stringify({
          data: dataJSON.data,
          salts: dataJSON.salts,
        }),
        headers: {
          'x-functions-key': cryptarAPIDemoAppKey,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      }, 
      3, 500);

      if (!response.ok) {
        setValidationFailed(true);
      }

      const result = (await response.json()) as DataJSON;
      setDoubleCheckedDataJSON(
        result.hash === dataJSON.hash
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const validateDataHash = async (
  data: FileInfo,
  proofFile: FileInfo,
  setProofKeyIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof data.file === 'undefined' || typeof proofFile.file === 'undefined')
      return;

    let dataContent = await data.file.text();
    let proofContent = await proofFile.file.text();

    if (typeof dataContent === 'string' && typeof proofContent === 'string') {
      const dataJSON: DataJSON = JSON.parse(dataContent);
      const proofJSON: ProofJSON = JSON.parse(proofContent);
      setProofKeyIsValid(
        dataJSON.hash === proofJSON.dataHash
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const validateProof = async (
  proofFile: FileInfo,
  setProofIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>
) => {
  try {
    if (typeof proofFile.file === 'undefined') return;

    let proofContent = await proofFile.file.text();
    if (typeof proofContent === 'string') {
      const proofJSON: ProofJSON = JSON.parse(proofContent);

      const response = await sendRequestWithRetry(
        cryptarServerURL +
          '/api/SparseMerkleTree/' +
          proofJSON.smtId +
          '/' + proofJSON.dataHash,
        {
          method: 'GET',
          headers: {
            'x-functions-key': cryptarAPIDemoAppKey,
            'Content-Type': 'application/json',
            Accept: 'application/json',            
          },
        }, 
        3, 500);

      if (!response.ok) {
        setValidationFailed(true);
      }

      const result = await response.json();
      setProofIsValid(
        result.hasOwnProperty('inclusionProofs')
          ? ValidationState.Valid
          : ValidationState.Invalid
      );
    }
  }
  catch {
    setValidationFailed(true);
  }
};

const runValidations = (certificateFile: FileInfo, dataFile: FileInfo, proofFile: FileInfo,
  setPdfIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setDoubleCheckedDataJSON: React.Dispatch<React.SetStateAction<ValidationState>>,
  setProofKeyIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setProofIsValid: React.Dispatch<React.SetStateAction<ValidationState>>,
  setValidationFailed: React.Dispatch<React.SetStateAction<boolean>>,
  pdfIsValid: ValidationState, doubleCheckedDataJSON: ValidationState, proofKeyIsValid: ValidationState
) => {
  const PDFValidation = async () =>
    await validatePDF(certificateFile, dataFile, setPdfIsValid, setValidationFailed);
  const DataJsonValidation = async () =>
    await validateDataJSON(dataFile, setDoubleCheckedDataJSON, setValidationFailed);
  const DataHashValidation = async () =>
    await validateDataHash(dataFile, proofFile, setProofKeyIsValid, setValidationFailed);
  const ProofValidation = async () =>
    await validateProof(proofFile, setProofIsValid, setValidationFailed);

  PDFValidation().catch(() => setValidationFailed(true));
  DataJsonValidation().catch(() => setValidationFailed(true));
  DataHashValidation().catch(() => setValidationFailed(true));

  if (
    pdfIsValid === ValidationState.Valid &&
    doubleCheckedDataJSON === ValidationState.Valid &&
    proofKeyIsValid === ValidationState.Valid
  )
    ProofValidation().catch(() => setValidationFailed(true));
  else {
    setProofIsValid(ValidationState.Notchecked);
  }
}

function App() {
  const defaultFileInto: FileInfo = {
    name: 'drag & drop file',
  };
  const [certificateFile, setCertificatedFile] = useState(defaultFileInto);
  const [dataFile, setDataFileFile] = useState(defaultFileInto);
  const [proofFile, setProofFileFile] = useState(defaultFileInto);

  const [pdfIsValid, setPdfIsValid] = useState(ValidationState.Notchecked);
  const [doubleCheckedDataJSON, setDoubleCheckedDataJSON] = useState(
    ValidationState.Notchecked
  );
  const [proofKeyIsValid, setProofKeyIsValid] = useState(
    ValidationState.Notchecked
  );
  const [proofIsValid, setProofIsValid] = useState(ValidationState.Notchecked);

  const [validationFailed, setValidationFailed] = useState(false);

  useEffect(() => {
    if(validationFailed) {
      setPdfIsValid(ValidationState.Notchecked);
      setDoubleCheckedDataJSON(ValidationState.Notchecked);
      setProofKeyIsValid(ValidationState.Notchecked);
      setProofIsValid(ValidationState.Notchecked);
    } else {
      runValidations(certificateFile, dataFile, proofFile, setPdfIsValid, setDoubleCheckedDataJSON,
        setProofKeyIsValid, setProofIsValid, setValidationFailed, pdfIsValid, doubleCheckedDataJSON, proofKeyIsValid);
    }
  }, [
    certificateFile,
    dataFile,
    proofFile,
    pdfIsValid,
    doubleCheckedDataJSON,
    proofKeyIsValid,
    proofIsValid,
    validationFailed
  ]);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item md={12} lg={7} container>
          <Grid item xs={12} sx={{ ml: 3, mt: 2 }}>
            <Grid item>
              <img src={logo} alt="Cryptar logo" />
            </Grid>
          </Grid>
          <Grid item xs={12} container direction="column" sx={{ ml: 2, mr: 2 }}>
            <Grid item container spacing={2} sx={{ mt: 2, mb: 6 }}>
              <Grid item sm={4} xs={12}>
                <FileUploader
                  headerText="CERTIFICATE"
                  fileIcon={pdfFile}
                  fileType="application/pdf"
                  fileInfo={certificateFile}
                  setFile={setCertificatedFile}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FileUploader
                  headerText="DATA"
                  fileIcon={jsonFile}
                  fileType="application/json"
                  fileInfo={dataFile}
                  setFile={setDataFileFile}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FileUploader
                  headerText="PROOF"
                  fileIcon={jsonFile}
                  fileType="application/json"
                  fileInfo={proofFile}
                  setFile={setProofFileFile}
                />
              </Grid>
            </Grid>
            <Grid item sx={{ xs: { mt: 3 } }}>
              <FieldValueTable fileInfo={dataFile} />
            </Grid>
            {validationFailed ?
            <Grid item container direction="row" sx={{ mt: 2 }}>
              <Typography sx={{marginTop: '5px'}}>A validation error occured, please retry!</Typography>
              <Button
                onClick={() => {
                  runValidations(certificateFile, dataFile, proofFile, setPdfIsValid, setDoubleCheckedDataJSON, setProofKeyIsValid, 
                    setProofIsValid, setValidationFailed, pdfIsValid, doubleCheckedDataJSON, proofKeyIsValid);
                  setValidationFailed(false);
                }}
                sx={{
                  background: '#781e69',
                  color: 'white',
                  marginLeft: '20px',
                  border: 1,
                  borderColor: '#781e69',
                  borderRadius: 20,
                  pl: 3,
                  pr: 3,
                  mr: 2,
                  '&:hover': {
                    backgroundColor: '#fff',
                    color: '#781e69',
                    boxShadow: 5,
                  },
                }}
              >
                <Typography variant="button">Retry</Typography>
              </Button>
            </Grid> : <></>}
            <Grid item spacing={2} container direction="row" sx={{ mt: 2 }}>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="PDF matches DATA.JSON"
                  validationState={pdfIsValid}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="DATA JSON instact"
                  validationState={doubleCheckedDataJSON}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="Proof file matches DATA"
                  validationState={proofKeyIsValid}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <ValidationItem
                  text="Certificate still valid"
                  validationState={proofIsValid}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} lg={5}>
          <Grid item xs={12} sx={{ ml: 3, mt: 2 }}>
            <PdfPreview fileInfo={certificateFile} />
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default App;
