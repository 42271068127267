import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import {
  ValidationItemProps,
  ValidationState,
  ValidationStateImageProps,
} from '../../interfaces';
import valid from '../../img/icons8-done-96.svg';
import invalid from '../../img/icons8-multiply-96.png';
import React from 'react';

const ValidationItemContainer = styled.section`
  position: relative;
  border: 1px solid darkgray;
  padding: 35px 20px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f8f8f8;
`;

function ValidationStateImage(props: ValidationStateImageProps) {
  if (props.validationState === ValidationState.Valid)
    return (
      <React.Fragment>
        <img
          src={valid}
          alt="validation icon"
          loading="lazy"
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            height: '40px',
          }}
        />
      </React.Fragment>
    );
  else if (props.validationState === ValidationState.Invalid)
    return (
      <React.Fragment>
        <img
          src={invalid}
          alt="validation icon"
          loading="lazy"
          style={{
            position: 'absolute',
            bottom: '0px',
            right: '0px',
            height: '40px',
          }}
        />
      </React.Fragment>
    );
  else return <React.Fragment></React.Fragment>;
}

function ValidationItem(props: ValidationItemProps) {
  return (
    <ValidationItemContainer>
      <Typography>{props.text}</Typography>
      <ValidationStateImage validationState={props.validationState} />
    </ValidationItemContainer>
  );
}

export default ValidationItem;
